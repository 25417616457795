import { computed, action } from 'mobx'
import { task } from 'mobx-task'

export default class RoomViewModel {
  constructor(room, roomStore) {
    this.room = room
    this.roomStore = roomStore
  }

  @computed
  get participants() {
    return this.room.activeParticipants.items
  }

  /**
   * Sets the Room.
   */
  @action.bound
  setRoom(room) {
    this.room = room
  }

  join = task.resolved(async () => {
    const connectionDescriptor = await this.roomStore.joinRoom(this.room.id)
    switch (connectionDescriptor.type) {
      case 'Whereby':
        window.open(connectionDescriptor.roomUrl, '_blank')?.focus()
        break
      case 'Local':
        break
    }
  })
}
