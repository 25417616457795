import { Store } from 'libx'
import LogRocket from 'logrocket'
import { getFeatureToggleClient } from 'misc/featureToggles'

export default class TrackingStore extends Store {
  constructor(props) {
    super(props)

    // Identifies user upon workspace selection
    this.rootStore.sessionStore.onWorkspaceSelected((workspace, member) => {
      this.identify(member.userId, {
        first_name: member.givenName,
        last_name: member.familyName,
        email: member.email,
        workspace_id: member.workspaceId,
      })

      LogRocket.identify(member.userId, {
        name: `${member.givenName} ${member.familyName}`,
        email: member.email,

        // Add your own custom user variables here, ie:
        workspaceId: member.workspaceId,
      })

      // set the referrer
      if (window.Intercom) {
        this.rootStore.intercomStore.update({
          referrer_source: member.referrer,
        })
      }
      // have Oribi track user email
      window.ORIBI && window.ORIBI.api('setUserEmail', member.email)

      if (
        workspace.id === '1' &&
        process.env.NODE_ENV !== 'development' &&
        getFeatureToggleClient().variation(
          'Portals.UseHubSpotConversations',
          false
        )
      ) {
        this.rootStore.freshbooksAPI.getToken().then((result) => {
          const token = result.token
          window.hsConversationsSettings = {
            identificationEmail: member.email,
            identificationToken: token,
          }
          window.HubSpotConversations.widget.load()
        })
      }

      // for zendesk
      if (window.$zopim && window.$zopim.livechat) {
        window.$zopim.livechat.setName(member.displayName)
        window.$zopim.livechat.setEmail(member.email)
        window.$zopim.livechat.setPhone(member.phone)
      }
    })
  }

  isImpersonating() {
    try {
      return this.rootStore.authStore.user.impersonated
    } catch (err) {
      return false
    }
  }

  // Handles identifying, tracking, and paging
  identify(userId, data) {
    if (this.isImpersonating()) {
      return
    }

    this.setDataLayer('identify', {
      userId: userId,
      email: data.email,
      workspaceId: data.workspace_id,
    })

    window.heap.identify(userId)
    window.heap.addUserProperties({
      'First Name': data.first_name,
      'Last Name': data.last_name,
      Email: data.email,
    })
  }

  track(action, data) {
    if (this.isImpersonating()) {
      return
    }

    // console.log('track', { action: action, data: data })
    const sessionStore = this.rootStore.sessionStore
    if (sessionStore.workspace && sessionStore.workspace.id === '4') {
      action += ' - Onboarding'
    } else if (sessionStore.workspace && sessionStore.workspace.id !== '1') {
      action += ' - Worklayer'
    }
  }

  page(pageName) {
    // if (this.isImpersonating()) {
    //   return
    // }
  }

  // Configures data for Google Tag Manager
  setDataLayer(event, data) {
    if (window.dataLayer) {
      data = {
        ...data,
        event: event,
      }

      if (process.env.NODE_ENV === 'development') {
        // console.log('dataLayer update', {event: event, data: data})
      } else if (process.env.NODE_ENV === 'production') {
        window.dataLayer.push(data)
      }
    }
  }

  // Callback for use in the WorkspaceSelectorStore
  accountSignup(data) {
    if (this.isImpersonating()) {
      return
    }

    this.identify(data.user_id, {
      first_name: data.given_name,
      last_name: data.family_name,
      email: data.email,
      workspace_id: data.workspace_id,
    })

    this.setDataLayer('accountSignup', {
      firstName: data.given_name,
      lastName: data.family_name,
      email: data.email,
    })

    if (window.growsumo) {
      const publicId = this.rootStore.sessionStore.user.userPublicId
      window.growsumo.data.name = `${data.given_name} ${data.family_name}`
      window.growsumo.data.email = data.email
      window.growsumo.data.customer_key = publicId ?? data.user_id

      window.growsumo.createSignup()
    }
  }
}
