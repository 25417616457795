import React from 'react'
import cx from 'classnames'
import { observer } from 'mobx-react'
import Link from 'components/Link'
import { FlexContainer, FlexItem } from 'components/Flex'
import Icon from 'components/Icon'
import { Dropdown, DropdownItem, DropdownItemIcon } from 'components/Dropdown'
import Avatar from 'components/Avatar'
import NotificationsViewToggler from 'notifications/components/NotificationsViewToggler'
import RequirePerms from 'iam/containers/RequirePerms'
import links from 'misc/links'
import { makeSink } from 'components/ComponentSink'
import './header-background@2x.png'
import 'assets/img/logo-white@2x.png'
import './styles.sass'
import { getFeatureToggleClient } from 'misc/featureToggles'
import Cookies from 'js-cookie'
import { sessionStorage } from 'misc/storage'
import { sessionReturnOptionsKey } from 'misc/MagicLinkClient'

@observer
export default class Header extends React.Component {
  render() {
    const {
      rootStore: {
        authStore,
        sessionStore,
        workspaceSelectorStore,
        desktopNotificationStore,
        soundStore,
        multiWorkspaceNotificationStore,
        multiFactorAuthStore,
      },
      children,
      simplified,
      simplifiedLogo,
      backTargetUrl,
    } = this.props
    const workspace = sessionStore.workspace
    const logoutUrl =
      workspace.config.signup?.logout_url?.length > 0
        ? workspace.config.signup?.logout_url
        : null
    return (
      <header className={cx('app-header animated fadeIn is-faster')}>
        {simplified ? (
          <SimplifiedNavBar
            backTargetUrl={backTargetUrl}
            image={simplifiedLogo}
          />
        ) : (
          <Navbar
            member={sessionStore.member}
            user={sessionStore.user}
            workspace={workspace}
            workspaceSelectorStore={workspaceSelectorStore}
            multiFactorAuthStore={multiFactorAuthStore}
            supportsNotifications={desktopNotificationStore.isSupported}
            notificationsEnabled={desktopNotificationStore.isEnabled}
            onToggleNotificationsClick={() =>
              desktopNotificationStore.setEnabled(
                !desktopNotificationStore.isEnabled
              )
            }
            soundEnabled={soundStore.isEnabled}
            onToggleSoundClick={() =>
              soundStore.setEnabled(!soundStore.isEnabled)
            }
            onLogoutClick={() => authStore.logout(logoutUrl)}
            multiWorkspaceNotificationStore={multiWorkspaceNotificationStore}
          >
            {children}
          </Navbar>
        )}
      </header>
    )
  }
}

export const HeaderTitle = ({ children }) => (
  <div className="header-container title-container">
    <h1 className="header-title title is-white has-text-centered is-semibold">
      {children}
    </h1>
  </div>
)

export const HeaderContainer = ({ children }) => (
  <div className="header-container general-container">{children}</div>
)

let Navbar = ({
  member,
  user,
  multiWorkspaceNotificationStore,
  workspace,
  children,
  ...rest
}) => {
  const logo = workspace.branding.logos.primary_white
  const icon = workspace.branding.logos.icon_white
  const notificationsViewStore = multiWorkspaceNotificationStore.forWorkspace(
    workspace.id
  )
  return (
    <FlexContainer className="navbar">
      <FlexItem className="navbar-item">
        <Link to="/" className="brand">
          <img src={logo} alt={workspace.name} className="logo" />
          <img src={icon} alt={workspace.name} className="logo-icon" />
        </Link>
      </FlexItem>
      <FlexItem className="navbar-center" stretch>
        {children}
      </FlexItem>
      <FlexContainer className="navbar-item navbar-right">
        <NotificationsViewToggler
          className="navbar-notification"
          store={notificationsViewStore}
        >
          <a
            href="#"
            onClick={(e) =>
              e.preventDefault() || notificationsViewStore.toggle()
            }
            className={cx(
              'action button is-icon-only is-white notifications-button',
              notificationsViewStore.showing === false && 'is-faded'
            )}
          >
            <Icon
              material
              name={
                notificationsViewStore.unreadCount === 0
                  ? 'notifications none'
                  : 'notifications'
              }
            />
          </a>
        </NotificationsViewToggler>
        <UserInfo
          name={member.displayName}
          avatar={member.avatar}
          impersonator={user.impersonator && user.impersonator.email}
        />
        <NavbarActions workspace={workspace} member={member} {...rest} />
      </FlexContainer>
    </FlexContainer>
  )
}

let SimplifiedNavBar = ({ image, backTargetUrl }) => {
  const returnOptionsPayload = sessionStorage.getItem(sessionReturnOptionsKey)
  const payload = returnOptionsPayload ? JSON.parse(returnOptionsPayload) : null
  const returnOptions = payload ? payload.returnOptions : null

  const customReturnUrl = returnOptions
    ? returnOptions.url
    : Cookies.get('__return_target')
  const customReturnLabel = returnOptions
    ? returnOptions.actionLabel
    : Cookies.get('__return_label')

  const backLink = customReturnUrl ?? backTargetUrl

  return (
    <FlexContainer className="navbar">
      <FlexItem
        className={cx('navbar-logo-center', customReturnUrl && 'hide-mobile')}
      >
        <img src={image} className="navbar-image" />
      </FlexItem>
      <BackButtonLink to={backLink} label={customReturnLabel} />
    </FlexContainer>
  )
}

Navbar = observer(Navbar)
SimplifiedNavBar = observer(SimplifiedNavBar)

const UserInfo = ({ avatar, name, impersonator }) => (
  <div className="user-info animated fadeIn">
    <FlexContainer>
      {impersonator && (
        <FlexItem vcentered>
          <span className="impersonating">Impersonating</span>
          &nbsp;
        </FlexItem>
      )}
      <FlexItem vcentered>
        <Avatar size={24} url={avatar} alt={name} className="avatar" />
      </FlexItem>
      <FlexItem className="name">{name}</FlexItem>
    </FlexContainer>
  </div>
)

const NavbarActions = ({
  member,
  onLogoutClick,
  workspace,
  workspaceSelectorStore,
  multiFactorAuthStore,
}) => {
  const viewTeams =
    workspaceSelectorStore.rootStore.sessionStore.member.hasPermission(
      'CREATE_TEAM'
    ) || workspaceSelectorStore.rootStore.sessionStore.teamMembers.length > 0
  const workspaceConfig = workspace.config || {}
  const magicLinkEnabled =
    workspaceConfig.public_api_config?.signed_url_enabled &&
    workspaceConfig.external_customers_config?.enabled

  return (
    <div className="actions animated fadeIn">
      <Dropdown
        position="right"
        button={
          <a
            className="action button is-icon-only is-white is-faded"
            data-test="toggle-header-dropdown"
          >
            <Icon material name="more vert" />
          </a>
        }
      >
        <DropdownItem to={links.profile()} data-test="goto-profile">
          <DropdownItemIcon material name="account circle" />
          Account
        </DropdownItem>
        {!magicLinkEnabled && (
          <>
            {workspace.features.billing.enabled && (
              <DropdownItem to={links.billing()} data-test="goto-billing">
                <DropdownItemIcon material name="credit card" />
                Billing
              </DropdownItem>
            )}
            <EnrollMultiFactorAuthDropdownItem
              multiFactorAuthStore={multiFactorAuthStore}
            />
          </>
        )}

        <RequirePerms
          subject={workspaceSelectorStore.rootStore.sessionStore.member}
          anyOf={['DOCS_VIEW']}
        >
          <DropdownItem
            to={links.allDocuments()}
            data-test="goto-all-documents"
          >
            <DropdownItemIcon material name="attach file" />
            All Documents
          </DropdownItem>
        </RequirePerms>

        {!magicLinkEnabled && (
          <>
            {viewTeams && (
              <DropdownItem to={links.teams()} data-test="goto-teams">
                <DropdownItemIcon material name="group" />
                Teams
              </DropdownItem>
            )}

            <hr />
            {workspace.config.custom_menu &&
              workspace.config.custom_menu.items
                .filter((e) => e.target === 'All' || e.target === 'Portal')
                .map(
                  (e, i) =>
                    (e.audience === '0' ||
                      member.roleIds.includes(e.audience)) && (
                      <DropdownItem href={e.url} target="_blank" key={i}>
                        <DropdownItemIcon material name="link" />
                        {e.label}
                      </DropdownItem>
                    )
                )}

            {!workspaceSelectorStore.domainMatchedWorkspace && (
              <DropdownItem
                onClick={() => workspaceSelectorStore.showSelector()}
              >
                <DropdownItemIcon material name="compare arrows" />
                Switch Workspace
              </DropdownItem>
            )}

            <DropdownItem onClick={() => onLogoutClick()} data-test="logout">
              <DropdownItemIcon material name="power settings new" />
              Log out
            </DropdownItem>
          </>
        )}
      </Dropdown>
    </div>
  )
}

const EnrollMultiFactorAuthDropdownItem = observer(
  function EnrollMultiFactorAuthDropdownItem({ multiFactorAuthStore }) {
    if (
      !getFeatureToggleClient().variation(
        'Portals.MultiFactorAuthentication',
        false
      )
    ) {
      return null
    }

    const click = async () => {
      const url = await multiFactorAuthStore.getEnrollmentUrl()
      window.open(url)
    }

    // If the user is already enrolled in MFA, then don't show the option
    // to enroll again.
    // There is a caveat with this approach: we won't observe that the user
    // has enrolled in MFA until the next time they log in.
    if (multiFactorAuthStore.isUserEnrolled) {
      return null
    }

    return (
      <DropdownItem onClick={click}>
        <DropdownItemIcon material name="shield" />
        Enroll in multi-factor authentication
      </DropdownItem>
    )
  }
)

const BackButtonLink = ({ to, label }) => {
  return (
    <a
      href={to}
      className={cx(
        'button is-icon-only is-white is-faded animated fadeIn back-button-link',
        label && 'with-label'
      )}
      data-test="header-back-button"
    >
      <img src={require('./ic_back.svg')} className={'navbar-button'} />
      {label ? (
        <span className="back-button-link__label is-white">{label}</span>
      ) : null}
    </a>
  )
}

// The following 2 components lets us declare props for the header
// in sub components, even though the actual header is mounted further up the tree.
// This is used to prevent remounting with React Router, which will kill certain
// animations.
const { Sink, Renderer } = makeSink(Header)
export const HeaderRenderer = Renderer
export const HeaderSink = Sink
