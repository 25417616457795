import { Store } from 'libx'
import Job from 'jobs/Job'
import { action, observable, computed } from 'mobx'
import { task } from 'mobx-task'
import { makeMomentSorter } from '@taxfyle/web-commons/lib/utils/arrayUtil'
import memoize from 'memoizee'
import { getFeatureToggleClient } from 'misc/featureToggles'
import { mapGrpcJobToJobDTO } from '@taxfyle/web-commons/lib/jobs/jobGrpcDtoMapper'
import { RealtimeEvent } from '@taxfyle/api-internal/internal/realtime_pb'
import { timestampToISO } from '@taxfyle/web-commons/lib/utils/grpcUtil'
import * as moment from 'moment'
import { mapGrpcSearchableJobToJobDTO } from '../utils/searchableJobGrpcDtoMapper'
import { JobsV3API } from 'jobs/API'
import { map, filter } from 'rxjs/operators'

export default class ProjectStore extends Store {
  @observable
  loading = false

  /**
   * Job events from the realtime channel.
   */
  jobEvents$ = this.rootStore.api.jobsV3.events$.pipe(
    filter((e) => e !== null && e !== undefined),
    map((e) => this._handleRemoteUpdate(e))
  )

  constructor() {
    super(...arguments)
    this.rootStore.authStore.onLogout(
      action(() => {
        this.projects.clear()
      })
    )
    this.projects = this.collection({
      model: Job,
    })

    this.jobs = this.projects // Until we rename projects to jobs
    // Let's prepare all for the realtime events.
    // We have a toggle in the APIs to use DotNET API or WorkAPI.
    this.prepareRealtimeEvents()
    this.prepareRealtimeEventsV3()
    this.freshenUp = this.freshenUp.bind(this)
    this.forConversation = this.forConversation.bind(this)
    this.getJob = this.getJob.bind(this)
    this.fetchProject = this.fetchProject.wrap((fn) =>
      memoize(fn.bind(this), { promise: true, length: 1 })
    )

    this.rootStore.sessionStore.onWorkspaceSelected(() => {
      this.jobs.clear()
      this.fetchProject.clear()
    })
  }

  prepareRealtimeEvents() {
    this.rootStore.api.jobs.on('created', this.onRemoteUpdate)
    this.rootStore.api.jobs.on('updated', this.onRemoteUpdate)
    this.rootStore.api.jobs.on('patched', this.onRemoteUpdate)
  }

  prepareRealtimeEventsV3() {
    this.jobEvents$.subscribe()
  }

  @action.bound
  setLoading(loading) {
    this.loading = loading
  }

  @computed
  get orderedJobs() {
    return this.projects
      .map((x) => x)
      .sort(makeMomentSorter('desc', (x) => x.dateModified))
  }

  @action.bound
  onRemoteUpdate(data) {
    const useDotnetRealtimeEvents = getFeatureToggleClient().variation(
      'Portals.UseV3RealtimeEvents',
      false
    )

    if (
      data.customer_user_id !== this.rootStore.sessionStore.user.userId ||
      useDotnetRealtimeEvents
    ) {
      return
    }

    return this.freshenUp(data).then(
      action(async () => {
        const updated = this.projects.set(data)
        return updated
      })
    )
  }

  _handleRemoteUpdate(realtimeEvent) {
    const isJobUpdatedEvent =
      realtimeEvent.getTypeCase() === RealtimeEvent.TypeCase.JOB_UPDATED

    const jobProtoDto = isJobUpdatedEvent
      ? realtimeEvent.getJobUpdated()?.toObject()?.job
      : realtimeEvent.getJobCreated()?.toObject()?.job

    const jobDto = mapGrpcJobToJobDTO(jobProtoDto)

    return this.freshenUp(jobDto).then(
      action(async () => {
        const updated = this.projects.set(jobDto)
        return updated
      })
    )
  }

  forConversation(conversationId) {
    return this.projects.find((x) => x.conversationId === conversationId)
  }

  @task
  async fetchProject(projectId) {
    return this.fetch(projectId)
  }

  async fetch(id) {
    return this.rootStore.api.jobsV3
      .getJob(id)
      .then(mapGrpcJobToJobDTO)
      .then(this.freshenUp)
      .then(action(this.projects.set))
  }

  getJob(id) {
    return this.fetchProject(id)
  }

  @computed
  get currentUserTeams() {
    const teamMembers = this.rootStore.sessionStore.teamMembers
    return teamMembers.map((m) => {
      if (m.hasPermission('VIEW_TEAM_JOBS')) {
        return m.team
      }
      return undefined
    })
  }

  @task
  async legacyFetchProjects(params = {}) {
    const result = await this.rootStore.retryOnNetworkFailure(() =>
      this.rootStore.api.jobs.find({
        workspace_id: this.rootStore.sessionStore.workspace.id,
        $limit: params.limit,
        $skip: params.skip,
        team_id: params.teamId,
        exclude_fields: [],
        searchText: params.searchText,
        role: params.role,
        type: params.type,
        status: params.status,
      })
    )
    if (result === false) {
      return
    }

    this.fetchTicketsForJobs(result.data.map((job) => job.id))?.then()

    await Promise.all([
      result.data.map((job) => this.freshenUp(job)),
      this.fetchProgressionsForJobs(result.data.map((job) => job.id)),
    ])
    return {
      ...params,
      data: this.projects.set(result.data),
    }
  }

  @task
  async fetchJobs(params) {
    const useV3SearchJobs = getFeatureToggleClient().variation(
      'CustomerPortal.UseV3SearchJobs',
      false
    )

    return useV3SearchJobs
      ? this.fetchProjects(params)
      : this.legacyFetchProjects(params)
  }

  @task
  async fetchProjects(params) {
    const filter = this._mapParamsToFilter(params)
    const fetchLegendSettingAndMapToDto = async (job) => {
      const legend = await this.rootStore.legendSettingsStore.fetch(
        job.legendId,
        job.legendVersion
      )

      return mapGrpcSearchableJobToJobDTO(job, legend)
    }

    const res = await this.rootStore.retryOnNetworkFailure(() =>
      this.rootStore.api.jobsV3.searchJobs({
        filter,
        searchText: params?.searchText,
        pageSize: params?.limit,
        pageToken: params?.after,
        scope: JobsV3API.searchJobScopeOption.mine,
        orderBy: 'date_deadline asc, date_submitted asc',
      })
    )

    const jobIds = res.jobsList.map((job) => job.id)

    this.fetchTicketsForJobs(jobIds)?.then()

    const result = await Promise.all([
      Promise.all(res.jobsList.map(fetchLegendSettingAndMapToDto)),
      res.jobsList.map(mapGrpcSearchableJobToJobDTO).map(this.freshenUp),
      this.fetchProgressionsForJobs(jobIds),
    ])

    return {
      data: this.projects.set(result[0]),
      cursor: res.nextPageToken,
    }
  }

  _mapParamsToFilter(params) {
    const workspaceFilter = `workspace_id=${this.rootStore.sessionStore.workspace.id}`
    const statusFilter = this._mapStatusFilter(params)
    const typeFilter = this._mapTypeFilter(params)

    return params?.status
      ? `(${workspaceFilter} AND ${typeFilter}) AND ${statusFilter}`
      : `${workspaceFilter} AND ${typeFilter}`
  }

  _mapStatusFilter(params) {
    if (params?.status === 'CURRENT') {
      return this._currentFilter()
    }

    return params?.status ? `status=${params.status}` : ''
  }

  _mapTypeFilter(params) {
    const userPublicId = this.rootStore.sessionStore.user.userPublicId
    const clientFilter = `clients.user_public_id:"${userPublicId}"`
    const currentUserTeamIds =
      this.currentUserTeams
        .filter((team) => team !== undefined)
        ?.map((t) => t.id) ?? []

    if (params.teamId) {
      return `client_team_id=${params.teamId}`
    }

    if (params?.type === 'CLIENT' && params?.role === 'CHAMPION') {
      return clientFilter
    }

    // This means that we are fetching all the jobs and we need to include the
    // clean_team_id by team in the filter
    const teamIdsFilter = currentUserTeamIds
      .map((id) => `client_team_id=${id}`)
      .join(' OR ')

    return teamIdsFilter ? `${clientFilter} OR ${teamIdsFilter}` : clientFilter
  }

  _currentFilter() {
    const start = moment().startOf('year').toJSON()
    const end = moment().endOf('year').toJSON()

    const claimed = '(status=CLAIMED OR status=IDLE OR status=ON_HOLD)'
    const created = `((status=UNDER_CONSTRUCTION OR status=INFO_GATHERING OR status=UNCLAIMED) AND (date_created >= "${start}" AND date_created <= "${end}"))`
    const submitted = `(date_submitted >= "${start}" AND date_submitted <= "${end}")`

    return `(${submitted} OR ${claimed} OR ${created})`
  }

  @task
  async calculatePrice(jobId) {
    const useV3GetQuote = getFeatureToggleClient().variation(
      'CustomerPortal.UseV3GetQuote',
      false
    )

    return useV3GetQuote
      ? this.rootStore.draftStore.getQuote(jobId).then((quote) => {
          return {
            coupon_amount: quote.coupon ? quote.coupon.amount : 0,
            primary_amount: quote.primaryAmount,
            total: quote.total,
            amount_due_now: quote.amountDueNow,
            job_scope_item_names: quote.jobScopeItemNames,
          }
        })
      : this.rootStore.api.jobs.price(jobId)
  }

  calculateAvailablePros(jobId) {
    const useV3GetMatchingProvidersCount = getFeatureToggleClient().variation(
      'Portals.UseV3GetMatchingProvidersCount',
      false
    )

    return useV3GetMatchingProvidersCount
      ? this.rootStore.draftStore.getMatchingProvidersCount(jobId)
      : this._getMatchingProvidersCountLegacy(jobId)
  }

  /**
   * Fetches the matching providers count using Work API.
   * @param {string} jobId
   * @returns {count: integer}
   */
  async _getMatchingProvidersCountLegacy(jobId) {
    return this.rootStore.api.providers.get(jobId)
  }

  async freshenUp(job) {
    // `job` is not a Project instance but what we get from the API.
    const members =
      job.members &&
      job.members.map((member) => ({
        workspace_id: job.workspace_id,
        user_public_id: member.user.user_public_id,
      }))

    if (job.layerConversation && job.status !== 'CLOSED') {
      // Don't wait for it.
      this.rootStore.messageStore
        .queueFetchMessagesForConversation(
          job.layerConversation,
          job.owner_team_id
        )
        .catch((err) => {
          console.error('Failed to retrieve conversation for job', job, err)
        })
    }

    return Promise.all([
      this.rootStore.memberStore.fetchManyByPublicId(members || []),
      job.owner_team_id &&
        this.rootStore.teamStore.fetchTeam(job.owner_team_id),
    ]).then(() => job)
  }

  @task
  async fetchProgressionsForJobs(jobIds) {
    const workspaceConfig = this.rootStore.sessionStore.workspace.config
    const newJobProgressionConfig = getFeatureToggleClient().variation(
      'HQ.UseUpdatedJobProgressionConfig',
      false
    )
    const jobProgressionFlowEnabled = newJobProgressionConfig
      ? workspaceConfig.job_progression_config &&
        workspaceConfig.job_progression_config.enabled
      : workspaceConfig.job_config &&
        workspaceConfig.job_config.job_progression_flow

    if (!jobProgressionFlowEnabled) {
      return
    }

    await this.rootStore.jobProgressionStore.fetchManyProgressions({
      jobIds: jobIds,
      workspaceId: this.rootStore.sessionStore.workspace.id,
    })
  }

  @task
  async fetchTicketsForJobs(jobIds) {
    try {
      await this.rootStore.jobTicketStore.fetchLatestOpenTicketsForJobs(jobIds)
    } catch (error) {
      console.error('Unable to retrieve open tickets for jobs', error)
    }
  }

  async createJob(data) {
    const createDraftJobPromise =
      data.owner_team_id == null
        ? this.rootStore.draftStore.createDraftJob(
            data.legend_id,
            data.legend_version
          )
        : this.rootStore.draftStore.createTeamDraftJob(
            data.legend_id,
            data.legend_version,
            data.owner_team_id
          )
    const jobId = await createDraftJobPromise
    return await this.fetchProject(jobId)
  }

  async copyJob(id) {
    return this.rootStore.api.jobsV3.copyJob(id)
  }

  async holdJob(id, hold) {
    const isHoldAndResumeV3 = getFeatureToggleClient().variation(
      'CustomerPortal.HoldAndResumeV3',
      false
    )

    if (!isHoldAndResumeV3) {
      return this.rootStore.api.jobs
        .patch(id, {
          status: hold ? 'ON_HOLD' : 'CLAIMED',
        })
        .then(action(this.projects.set))
    }

    return hold
      ? await this.rootStore.api.jobsV3.holdJob(id).then((res) =>
          this.projects.set({
            id,
            status: 'ON_HOLD',
            dateModified: timestampToISO(res.updateTime),
          })
        )
      : await this.rootStore.api.jobsV3.resumeJob(id).then((res) =>
          this.projects.set({
            id,
            status: 'CLAIMED',
            dateModified: timestampToISO(res.updateTime),
          })
        )
  }

  async setConsultationAvailability(jobId, availability) {
    await this.rootStore.api.consultations.setConsultationAvailability({
      jobId,
      availability,
    })

    return await this.fetch(jobId)
  }

  async updateDeadline(id, date) {
    return this.rootStore.api.jobs
      .patch(id, { date_deadline: date })
      .then(action(this.projects.set))
  }

  async updateJob(id, data) {
    return this.rootStore.api.jobs
      .patch(id, data)
      .then(action(this.projects.set))
  }

  @task
  async rename(id, name) {
    await this.rootStore.api.jobsV3
      .rename(id, name)
      .then(action(() => this.projects.set({ id, name })))
  }

  async submit(id, data) {
    if (data.provider_id) {
      return this.rootStore.api.providers.patch(id, data)
    }
    return this.updateJob(id, { ...data, status: 'UNCLAIMED' })
  }

  async providePaymentInfo(id, billingMethodId) {
    await this.rootStore.api.jobs.providePaymentInfo(id, {
      billing_method_id: billingMethodId,
    })
    const job = this.jobs.get(id)
    if (job) {
      job.set({ paymentInfoStatus: 'PROVIDED' })
    }
  }

  /**
   * Completes the info gathering stage for a job.
   *
   * @param id
   * @returns {Promise<void>}
   */
  async completeInfoGathering(id) {
    await this.rootStore.api.jobsV3.completeInfoGathering(id)
    await this.fetch(id)
  }

  async deleteJob(id) {
    return this.rootStore.draftStore
      .archive(id)
      .then(action(() => this.projects.remove(id)))
      .then(() =>
        this.rootStore.flashMessageStore
          .create({
            type: 'success',
            message: 'Job has been deleted',
          })
          .autoDismiss()
      )
  }

  async makeCorrections(id, questions, reason) {
    return this.rootStore.api.jobsV3.makeCorrections(id, questions, reason)
  }
}
